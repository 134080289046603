import React, { useState, useEffect } from 'react';
import { Trophy, Crown } from 'lucide-react';
import "../css/Leaderboard.css"
import logga from '../img/logga.png'
import Footer from './Footer';




const DepositLeaderboard = () => {
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Generate placeholder data for loading state
  const placeholderData = Array(10).fill({}).map((_, index) => ({
    name: 'No user :(',
    total_deposited: 0,
    avatar_url: logga,
    rank: index + 1
  }));

    const url = 'https://hstjorven.skinhansa.com/leaderboard-data';
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(url, {
            mode: 'cors',
            headers: {
              'Accept': 'application/json'
            }
          });
      
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          
          const data = await response.json(); // Parse JSON response
          // console.log(data); // Log the parsed data
          
          setLeaderboardData(data.data);
          setLoading(false);
          if(Object.keys(data.data).length === 0){
            setLoading(true)
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          setLoading(true);
        }
      };
    
      fetchData();
    }, [url]);

  const displayData = loading ? placeholderData : leaderboardData;

  return (
    <div className="leaderboard-container">
      <div className="leaderboard-wrapper">
        <h1 className="leaderboard-title">
          <Trophy size={32} />
          Top Depositors
          <Trophy size={32} />
        </h1>
        <h2 className='leaderboard-title'>USE CODE: HSTJORVEN</h2>

        <div className="podium-section">
          {/* Second Place */}
          <div className={`podium-place second-place ${loading ? 'animate-pulse' : ''}`}>
            <img
              src={loading ? logga : displayData[1]?.avatar_url}
              alt="Second Place"
              className="podium-avatar"
              onError={(e) => e.target.src = logga}
            />
            <div className="podium-block">
              <div className="podium-name">
                {loading ? 'No user :(' : displayData[1]?.name}
              </div>
              <div className="podium-amount">
                ${loading ? '0' : displayData[1]?.total_deposited.toLocaleString()}
              </div>
            </div>
          </div>

          {/* First Place */}
          <div className={`podium-place first-place ${loading ? 'animate-pulse' : ''}`}>
            <Crown size={32} className="crown-icon" />
            <img
              src={loading ? logga : displayData[0]?.avatar_url}
              alt="First Place"
              className="podium-avatar"
              onError={(e) => e.target.src = logga}
            />
            <div className="podium-block">
              <div className="podium-name">
                {loading ? 'No user :(' : displayData[0]?.name}
              </div>
              <div className="podium-amount">
                ${loading ? '0' : displayData[0]?.total_deposited.toLocaleString()}
              </div>
            </div>
          </div>

          {/* Third Place */}
          <div className={`podium-place third-place ${loading ? 'animate-pulse' : ''}`}>
            <img
              src={loading ? logga : displayData[2]?.avatar_url}
              alt="Third Place"
              className="podium-avatar"
              onError={(e) => e.target.src = logga}
            />
            <div className="podium-block">
              <div className="podium-name">
                {loading ? 'No user :(' : displayData[2]?.name}
              </div>
              <div className="podium-amount">
                ${loading ? '0' : displayData[2]?.total_deposited.toLocaleString()}
              </div>
            </div>
          </div>
        </div>

        <div className="players-list">
          {displayData.slice(3, 10).map((player) => (
            <div key={player.rank} className={`player-row ${loading ? 'animate-pulse' : ''}`}>
              <span className="player-rank">{player.rank}</span>
              <img
                src={loading ? logga : player.avatar_url}
                alt={player.name}
                className="player-avatar"
                onError={(e) => e.target.src = logga}
              />
              <span className="player-name">{player.name}</span>
              <span className="player-amount">
                ${player.total_deposited.toLocaleString()}
              </span>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DepositLeaderboard;