import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Main from './components/Main';
import './css/index.css';
import DepositLeaderboard from './components/LeaderBoard';

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
        <Routes>
          <Route path='/' element={<Main />} />
          <Route path='/Home' element={<Main />} />
          <Route path='/Leaderboard' element ={<DepositLeaderboard/>} />
        </Routes>
      </div>
    </BrowserRouter>

  );
}

export default App;
