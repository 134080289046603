import React, { useEffect } from "react";
// import TwitchLive from './TwitchLive';
import CasinoBox from './CasinoBox';
import BestOfferBox from './BestOfferBox';
import Youtube from './Youtube';
import Welcome from './Welcome';
import Footer from './Footer';
import Aos from 'aos';
import 'aos/dist/aos.css';
import '../css/Casinos.css'
import { Link } from "react-router-dom";

const Main = () => {
    useEffect(() => {
        Aos.init({ duration: 1000 });
    }, [])

    return (
        <div>
            <Welcome />
            {/* <TwitchLive /> */}
            <div className='Offers_background_div' >
                <BestOfferBox />
                <CasinoBox />
                {/* <Youtube /> */}
                <Footer />


                <Link className='Temp_button' to='/Leaderboard'>
                <p className='Temp_text'>Upcoming Leaderboard</p>
                </Link>+

            </div>

        </div>
    );
}

export default Main;