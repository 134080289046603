import React from "react";
import '../css/Casinos.css'

const Footer = () => {
    return (
        <div className='footer_text'>
            <span>BeGambleAware.org</span>
            <br></br>
            <span>© 2025 HSTjorvens Gambling Website</span>
        </div>
    );
}

export default Footer;