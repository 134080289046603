import React, { useState } from 'react';
import '../css/Navbar.css';
import { Link } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as Twitchicon } from '../img/Twitch_icon.svg';
import { ReactComponent as Tiktok } from '../img/Tiktok.svg';
import { ReactComponent as Youtube } from '../img/Youtube.svg';
import { ReactComponent as Instagram } from '../img/Instagram.svg';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import logga from '../img/logga.png';

const Navbar = () => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const hexcolor = "#2596be";

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setIsDropdownOpen(false);
    };

    return (
        <div className="Navbar">
            <Link className='logga' to='Home'>
                <img src={logga} alt="Logga" height='100px' width='100px' className="coins" />
                <p className='logga_text'>HSTjorven</p>
            </Link>

            {/* Mobile Dropdown Toggle */}
            <div className="mobile-dropdown-toggle" onClick={toggleDropdown}>
                {isDropdownOpen ? <CloseIcon style={{ color: hexcolor }} /> : <MenuIcon style={{ color: hexcolor }} />}
            </div>

            {/* Mobile Dropdown Menu */}
            {isDropdownOpen && (
                <div className="mobile-dropdown-menu">
                    <Link to='Home' className='mobile-dropdown-item' onClick={closeDropdown}>
                        <HomeIcon fontSize="medium" style={{ color: hexcolor }} />
                        <span className='Nav_text'>Home</span>
                    </Link>
                    
                    <a 
                        href="https://streamelements.com/hstjorven/leaderboard"
                        target="_blank"
                        rel="noopener noreferrer"
                        className='mobile-dropdown-item'
                        onClick={closeDropdown}
                    >
                        <LeaderboardIcon fontSize="medium" style={{ color: hexcolor }} />
                        <span className='Nav_text'>Leaderboard</span>
                    </a>
                    
                    <a 
                        href="https://streamelements.com/hstjorven/store"
                        target="_blank"
                        rel="noopener noreferrer"
                        className='mobile-dropdown-item'
                        onClick={closeDropdown}
                    >
                        <StoreIcon fontSize="medium" style={{ color: hexcolor }} />
                        <span className='Nav_text'>Store</span>
                    </a>
                </div>
            )}

            {/* Existing full-screen navigation */}
            <Link to='Home' className='home hover_box'>
                <div className='nav_button '>
                    <HomeIcon fontSize="large" style={{ color: hexcolor }} />
                    <h1 className='Nav_text'>Home</h1>
                </div>
            </Link>
            
            <a 
                href="https://streamelements.com/hstjorven/leaderboard"
                target="_blank"
                rel="noopener noreferrer"
                className='Leaderboard hover_box'
            >
                <div className='nav_button '>
                    <LeaderboardIcon fontSize="large" style={{ color: hexcolor }} />
                    <h1 className='Nav_text'>Leaderboard</h1>
                </div>
            </a>
            
            <a 
                href="https://streamelements.com/hstjorven/store"
                target="_blank"
                rel="noopener noreferrer"
                className='store hover_box'
            >
                <div className='nav_button'>
                    <StoreIcon fontSize="large" style={{ color: hexcolor }} />
                    <h1 className='Nav_text'>Store</h1>
                </div>
            </a>

            <div className='socials'>
                <a href="https://www.twitch.tv/hstjorven" target="_blank" rel="noopener noreferrer">
                    <div className='social_buttons '>
                        <Twitchicon className='icon' />
                    </div>
                </a>
                <a href="https://www.youtube.com/@hstjorven" target="_blank" rel="noopener noreferrer" className=''>
                    <div className='social_buttons'>
                        <Youtube className='icon' style={{ color: hexcolor }} />
                    </div>
                </a>
                <a href="https://www.instagram.com/hstjorven" target="_blank" rel="noopener noreferrer" className=''>
                    <div className='social_buttons '>
                        <Instagram className='icon' style={{ color: hexcolor }} />
                    </div>
                </a>
                <a href="https://www.tiktok.com/@hstjorven" target="_blank" rel="noopener noreferrer">
                    <div className='social_buttons '>
                        <Tiktok className='icon' />
                    </div>
                </a>
            </div>
        </div>
    )
}

export default Navbar;